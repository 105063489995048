<template>
  <div class="full-layout">
    <div class="filter-image" />
    <div class="main-content">
      <header>
        <NuxtLink :to="localePath('/')" class="web-logo" v-track:click="'Header,logo'">
          <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo">
          <img src="~/assets/images/ug-word.png" alt="UG INVESTMENT ADVISERS" class="word">
        </NuxtLink>
      </header>
      <slot />
    </div>
  </div>
</template>

<script setup>
useSiteHead()

</script>

<style lang="stylus" scoped>
.full-layout
  position relative
  width 100%
  min-height 100vh
  height 100%
  /**
    修正 Safari toolbar 蓋過高度問題
    https://juejin.cn/post/6997744474126303268
    https://app.asana.com/0/0/1204791186534408/f
   */
  // Safari Only Hack
  @supports (font: -apple-system-body) and (-webkit-appearance: none)
    height -webkit-fill-available
  .main-content
    position relative
    z-index 1
    min-height 100vh
    display flex
    flex-direction column
.filter-image
  position absolute
  width 100%
  height 100%
  overflow hidden
  &::before
    content ''
    position absolute
    width 105%
    height 105%
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-image url(@/assets/images/station.jpg)
    background-size cover
    background-position center center
    filter blur(20px)
    opacity 0.5
header
  width 100%
  height 76px
  display flex
  align-items center
  padding 0 16px
  .web-logo
    display flex
    align-items center
    padding 8px 11px
    img.logo
      width 30px
    img.word
      height 14px
      margin-left 16px
</style>